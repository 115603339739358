import store from "@/store";
import { genericGoToQuestionnaire, getCachedRecords, record } from "@/Study/helpers";
import { router } from "@/Study/main";
import { QueryConstraint, where } from "firebase/firestore";
import { getStorage, ref, uploadString } from "firebase/storage";

export const goToQuestionnaire = async () => {
    const { hasCorona } = store.state.study!;
    const used = Boolean(store.state.firebase!.claims.usedAt);

    const questionnaires = !used ? (hasCorona ? ['activePre', 'active'] : ['recovered']) : ['active'];
    genericGoToQuestionnaire(questionnaires)
}

type recordOrError = Promise<record[] | { error: any }>;

export const getRecords = async (prepareRecordsWithOptions: (...filters: QueryConstraint[]) => Promise<record[]>): recordOrError => {
    const isActive = router!.currentRoute.value.params.qualifier === 'active';
    
    const cachedRecords = !isActive ? await getCachedRecords() : null;
    if(cachedRecords) return cachedRecords;

    const records = await prepareRecordsWithOptions(where('hasCorona', '==', isActive));

    if(!cachedRecords && !isActive){
        const dataRef = ref(getStorage(), 'data.json');
        await uploadString(dataRef, JSON.stringify(records));
    }

    return records;
}