<template>
    <demographics @proceed='next'>
        <fieldset>
            <legend>האם {{ $parse('אתה', 'את') }} עדיין חולה בקורונה?</legend>
            <label multi-choice>
                <input type='radio' name='hasCorona' value='1' @click='setHasCorona($event)'/>
                כן
            </label>
            <label multi-choice>
                <input type='radio' name='hasCorona' value='0' @click='setHasCorona($event)'/>
                לא
            </label>
            <div class='error' v-show='study.dem.button === "invalid"'>
                נא לבחור אחת מהאפשרויות
            </div>
        </fieldset>
    </demographics>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { goToQuestionnaire } from './helpers';
import { updateSubject } from '@/Study/helpers';
import Demographics from '@/Study/Pages/Demographics.vue';
import { setHasCorona } from '@/Study/module';

export default defineComponent({
    components: { Demographics },
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    methods: {
        async next(){
            const { dem, hasCorona } = this.study;
            const { sex } = this.$store.state.user;

            if(typeof hasCorona !== 'boolean')
                return dem.button = 'invalid';

            if(dem.button !== 'continue')
                return;

            dem.button = 'loading';

            try{
                const updates = { sex: sex ?? null, age: dem.age ?? null, hasCorona };
                updateSubject(updates);
                await goToQuestionnaire();
                dem.button = 'continue';
            }catch{
                dem.button = 'error';
            }
        },
        setHasCorona({ target }: { target: HTMLInputElement }){
            this.study.dem.button = 'continue';
            setHasCorona(Boolean(parseInt(target.value)))
        },
    }
});
</script>